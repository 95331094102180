export const FreelancerEndpoint = {
  freelancerInfo: {
    url:(nu) => `/freelancer-expenses/?page=${nu}`,
    method: "GET",
  },

  freelancer: {
    url: `/freelancer-expenses/`,
    method: "POST",
  },

  freelancerDetails: {
    url: (id) => `freelancer-expenses/${id}/`,
    method: "GET",
  },
};
