import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import styles from "./styles.ProjectStatus.css";
import { ProjServices } from "../../apis/service/ProjServices";

export default function ProjectStatus() {
  const [availableProjects, setAvailableProjects] = useState([]);
  const [statusComment, setStatusComment] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [projectId, setProjectId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getAllProjects = () => {
    ProjServices.UserProjects()
      .then((data) => { setAvailableProjects(data); })
      .catch((error) => { console.error("Error fetching projects:", error); });
  };

  useEffect(() => { getAllProjects(); }, []);

  const handleSubmit = () => {
    if (!projectId) { toast.error("Please select a project."); return; }

    const changedFields = { status_comment: statusComment, status, name, };

    // Filter out empty fields to send only the changing part
    const filteredFields = Object.fromEntries(
      Object.entries(changedFields).filter(([_, value]) => value)
    );

    setIsSubmitting(true); // Set the submitting state to true while the request is ongoing

    ProjServices.AddProjectStatus(projectId, filteredFields)
      .then((response) => {
        console.log("Project status updated successfully:", response);
        toast.success("Project status updated!");

        // Reset form after successful submission
        setStatusComment(""); setStatus(""); setName(""); setProjectId(null);
      })
      .catch((error) => {
        console.error("Error updating project status:", error);
        toast.error("Failed to update project status.");
      })
      .finally(() => { setIsSubmitting(false); });
  };

  return (
    <div className="container">
      <center>
        <h2>Update Project Status</h2>
        <div className={styles.formRow}>
          <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
            <label>
              Select Project:
              <select
                value={projectId || ""}
                onChange={(e) => {
                  const selectedProject = availableProjects.find((project) => project.id === parseInt(e.target.value));
                  setProjectId(parseInt(e.target.value));
                  setName(selectedProject ? selectedProject.name : "");
                }}
              >
                <option value="">--Select a Project--</option>
                {availableProjects.map((project) => (
                  <option key={project.id} value={project.id}> {project.name} </option>
                ))}
              </select>
            </label>

            <label>
              Status Comment:
              <input type="text" value={statusComment} onChange={(e) => setStatusComment(e.target.value)} />
            </label>

            <label>
              Status:
              <select value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="ongoing">Ongoing</option>
                <option value="on_hold">On Hold</option>
                <option value="closed">Closed</option>
              </select>
            </label>

            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Updating..." : "Update"}
            </button>
          </form>
        </div>
      </center>
    </div>
  );
}
