import { Network } from "../Network";
import { ProjectEndpoint } from "../endpoints/ProjectEndpoint";

export class ProjServices {
  static UserProjects() {
    return Network.fetch(
      ProjectEndpoint.project.url,
      {
        method: ProjectEndpoint.project.method,
      },
      true
    );
  }
  static getDays() {
    return Network.fetch(
      ProjectEndpoint.getDays.url,
      {
        method: ProjectEndpoint.getDays.method,
      },
      true
    );
  }
  static getTodayProjects() {
    return Network.fetch(
      ProjectEndpoint.getTodayProjects.url,
      {
        method: ProjectEndpoint.getTodayProjects.method,
      },
      true
    );
  }
  static getAllProjects() {
    return Network.fetch(
      ProjectEndpoint.allProjects.url,
      {
        method: ProjectEndpoint.allProjects.method,
      },
      true
    );
  }
  static getProjectsCompilition() {
    return Network.fetch(
      ProjectEndpoint.getProjectsCompilition.url,
      {
        method: ProjectEndpoint.getProjectsCompilition.method,
      },
      true
    );
  }
  static getRetainerProjects() {
    return Network.fetch(
      ProjectEndpoint.retainerProjects.url,
      {
        method: ProjectEndpoint.retainerProjects.method,
      },
      true
    );
  }
  static getRetainerProjectsDetails(id) {
    return Network.fetch(
      ProjectEndpoint.retainerProjectsDetails.url(id),
      {
        method: ProjectEndpoint.retainerProjectsDetails.method,
      },
      true
    );
  }
  static getProjectsDetails() {
    return Network.fetch(
      ProjectEndpoint.projectsDetails.url,
      {
        method: ProjectEndpoint.projectsDetails.method,
      },
      true
    );
  }
  static getSingleProjectsDetails(id) {
    return Network.fetch(
      ProjectEndpoint.singleProjectsDetails.url(id),
      {
        method: ProjectEndpoint.singleProjectsDetails.method,
      },
      true
    );
  }
  static getMaintenanceProjects() {
    return Network.fetch(
      ProjectEndpoint.maintenanceProjects.url,
      {
        method: ProjectEndpoint.maintenanceProjects.method,
      },
      true
    );
  }
  static getMaintenanceProjectsDetails(id) {
    return Network.fetch(
      ProjectEndpoint.maintenanceProjectsDetails.url(id),
      {
        method: ProjectEndpoint.maintenanceProjectsDetails.method,
      },
      true
    );
  }
  static ProjectsExport(data) {
    return Network.fetch(
      ProjectEndpoint.ProjectsExport.url,
      {
        method: ProjectEndpoint.ProjectsExport.method,
        headers: {
          "content-type": "form-data",
        },
        body: data
      },
      true
    );
  }
}

