import { Network } from "../Network";
import {FreelancerEndpoint} from "../endpoints/FreelancerEndpoint"

export class FreelancerServices {
  static getFreelancerInfo(nu) {
    return Network.fetch(
      FreelancerEndpoint.freelancerInfo.url(nu),
      {
        method: FreelancerEndpoint.freelancerInfo.method,
      },
      true
    );
  }

  static addFreelancerInfo(data) {
    return Network.fetch(
      FreelancerEndpoint.freelancer.url,
      {
        method: FreelancerEndpoint.freelancer.method,
        headers: {
          "Content-Type": "application/json", // Specify the content type
        },
        body: JSON.stringify(data), // Convert data to JSON string
      },
      true
    );
  }
  
  static freelancerDetails(id) {
    return Network.fetch(
      FreelancerEndpoint.freelancerDetails.url(id),
      {
        method: FreelancerEndpoint.freelancerDetails.method,
      },
      true
    );
  }
}
