import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProjServices } from "../../../apis/service/ProjServices";
import "../ProjectRetainer/ProjectRetainerStyle.css"; 

const ProjectsDetails = () => {
    const [projects, setProjects] = useState([]);

    const getAllProjects = () => {
      ProjServices.getProjectsDetails()
        .then((res) => {setProjects(res); })
        .catch((err) => {console.log(err);});
    };
  
    useEffect(() => {getAllProjects();}, []);
  return (
    <div className="retainerProjects">
    <center><h2>Project Details</h2><br/></center>
    {projects.length > 0 ? (
      <ul>
        {projects.map((project) => (
          <li key={project.id} className="project-item">
            <h3>{project.name}</h3>
            <Link to={`/singleProjectsDetails/${project.id}`}>
              <button>Project Details</button>
            </Link>
          </li>
        ))}
      </ul>
    ) : (<p>No projects available</p>)}
  </div>
  )
}

export default ProjectsDetails