import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FreelancerServices } from "../../../apis/service/FreelancerServices";
import { ProjServices } from "../../../apis/service/ProjServices";
import styles from "./freelancerStyle.css";

const GetFreelancer = () => {
  const { nu } = useParams(); // Get the page number from URL params
  const [freelancerCount, setFreelancerCount] = useState([]); // Store freelancer data count
  const [freelancer, setFreelancer] = useState([]); // Store freelancer data
  const [availableProjects, setAvailableProjects] = useState([]); // Store available projects
  const [totalPages, setTotalPages] = useState(1); // Track total pages available
  const [currentPage, setCurrentPage] = useState(Number(nu) || 1); // Track current page number

  const getAllFreelancers = () => {
    FreelancerServices.getFreelancerInfo(currentPage)
      .then((res) => {
        setFreelancerCount(res.count);
        setFreelancer(res.results || []);
        setTotalPages(res.pages_number || 1);
      })
      .catch((err) => console.log(err));
  };

  const getAllProjects = () => {
    ProjServices.UserProjects()
      .then((data) => setAvailableProjects(data))
      .catch((error) => console.error("Error fetching projects:", error));
  };

  useEffect(() => {
    getAllFreelancers();
    getAllProjects();
  }, [currentPage, nu]);

  const getProjectName = (projectId) => {
    const project = availableProjects.find((proj) => proj.id === projectId);
    return project ? project.name : "This project is not in your working list";
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className={styles.container}>
      {Array.isArray(freelancer) && freelancer.length > 0 ? (
        <>
          {/*<p>Freelancer Count: {freelancerCount}</p>*/} 
          <table className="excelTable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Specialty</th>
                <th>Hours</th>
                <th>Fees</th>
                <th>Contracted</th>
                <th>Delivered</th>
                <th>Payment Due</th>
                <th>Paid</th>
                <th>Project Name</th>
              </tr>
            </thead>
            <tbody>
              {freelancer.map((item) => (
                <tr key={item.id}>
                  <td>{item.freelancer_name}</td>
                  <td>{item.specialty}</td>
                  <td>{item.hours}</td>
                  <td>{item.fees}</td>
                  <td>{item.date_contracted}</td>
                  <td>{item.date_delivered}</td>
                  <td>{item.date_payment_due}</td>
                  <td>{item.date_paid}</td>
                  <td>{getProjectName(item.project)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination buttons */}
          <div className="pagination">
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageClick(index + 1)}
                className={currentPage === index + 1 ? "active" : ""}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </>
      ) : (
        <p>No freelancers to display.</p>
      )}
    </div>
  );
};

export default GetFreelancer;
