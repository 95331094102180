import { useState } from "react";
import styles from "../main page/System.module.css";
import AddFreelancer from "./addFreelancer";
import GetFreelancer from "./getFreelancer";

const Freelancer = () => {
  const [tab, setTab] = useState("add");

  return (
    <div className={styles.container}>
      <main className={styles.contentCard}>
        <div className={styles.HoursSelector}>
          <button
            className={
              tab === "add" ? styles.select : styles.availableProjectButton
            }
            onClick={() => {
              setTab("add");
            }}
          >
            <p style={{ marginInline: "auto" }}>Add Freelancer</p>
          </button>

          <button
            className={
              tab === "List" ? styles.select : styles.availableProjectButton
            }
            onClick={() => setTab("List")}
          >
            <p style={{ marginInline: "auto" }}>List Of Freelancers</p>
          </button>
        </div>
        {tab === "add" && <AddFreelancer />}
        {tab === "List" && <GetFreelancer />}
      </main>
    </div>
  );
};

export default Freelancer;

 

