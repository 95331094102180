import { React, useState, useEffect } from "react";
import Slotsystem from "./Pages/slotSystem/main page/System";
import Login from "./Pages/Login/System/Login";
import SideBar from "./Components/SideBar";
import { useNavigate, Route, Routes } from "react-router-dom";
import Managers from "./Pages/slotSystem/managers";
import Projects from "./Pages/slotSystem/myProjects";
import Vacations from "./Pages/slotSystem/vacations";
import ProjectRetainer from "./Pages/slotSystem/ProjectRetainer/ProjectRetainer";
import ProjectRetainerDetails from "./Pages/slotSystem/ProjectRetainer/ProjectRetainerDetails";
import ProjectsDetails from "./Pages/slotSystem/ProjectsDetails/ProjectsDetails";
import SingleProjectsDetails from "./Pages/slotSystem/ProjectsDetails/SingleProjectsDetails";
import Freelancer from "./Pages/slotSystem/freelancer";
import ProjectMaintenance from "./Pages/slotSystem/ProjectMaintenance/ProjectMaintenance";
import ProjectMaintenanceDetails from "./Pages/slotSystem/ProjectMaintenance/ProjectMaintenanceDetails";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [title, setTitle] = useState("");
  const [isManager, setIsManager] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    // Check local storage for user information
    const loggedIn = localStorage.getItem("loggedIn");
    const username = localStorage.getItem("username");
    const title = localStorage.getItem("title");
    const isAdmin = localStorage.getItem("isAdmin");
    setIsManager(isAdmin)
    // const manager = localStorage.getItem("manager");
    // console.log(isAdmin, typeof isAdmin, "app js ");
    if (loggedIn === "true" && username && title) {
      // If the user was previously logged in, set their information
      setLoggedIn(true);
      setUsername(username);
      setTitle(title);
    }
  }, [loggedIn]);

  const handleLogin = (data) => {
    console.log(data);
    setLoggedIn(true);
    setUsername(data.username);
    setTitle(data.title_name);
    localStorage.setItem("loggedIn", "true");
    localStorage.setItem("username", data.username);
    localStorage.setItem("title", data.title_name);
    localStorage.setItem("isAdmin", data.is_board_member
    );

    // setIsManager(data.is_board_member)
  };
  const handleLogout = () => {
    // Remove the authentication token from localStorage
    localStorage.removeItem("bearer");
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("username");
    localStorage.removeItem("title");
    localStorage.removeItem("isAdmin");
    setLoggedIn(false);
    setUsername("");
    setTitle("");
    navigate("/login");
  };
  return (
    <>
      {loggedIn ? (
        <div>
          <div className="Sidebar">
            <SideBar
              username={username}
              title={title}
              handlelogout={handleLogout}
              manager={isManager}
            />
          </div>
          <div className="App">
            <Routes>
              <Route path="/" element={<Slotsystem />} index />
              <Route path="/login" element={<Login />} />
              <Route path="/Managers" element={<Managers />} />
              <Route path="/Projects" element={<Projects />} />
              <Route path="/vacations" element={<Vacations />} />
              <Route path="/ProjectRetainer" element={<ProjectRetainer />} />
              <Route path="/ProjectRetainer/:id" element={<ProjectRetainerDetails />} />
              <Route path="/ProjectsDetails" element={<ProjectsDetails />} />
              <Route path="/SingleProjectsDetails/:id" element={<SingleProjectsDetails />} />
              <Route path="/Freelancer" element={<Freelancer />} />
              <Route path="/ProjectMaintenance" element={<ProjectMaintenance />} />
              <Route path="/MaintenanceProjectsDetails/:id" element={<ProjectMaintenanceDetails />} />
            </Routes>
          </div>
        </div>
      ) : (
        <Login onLogin={handleLogin} />
      )}
    </>
  );
}

export default App;
