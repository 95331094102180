import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { ProjServices } from "../../../apis/service/ProjServices";
import "./SingleProjectsDetails.css";

const SingleProjectsDetails = () => {
  const { id } = useParams();
  const [project, setProject] = useState(null);
  const [employeesData, setEmployeesData] = useState([]);
  const [missingHours, setMissingHours] = useState([]);
  const [percentageCompletion, setPercentageCompletion] = useState([]);
  const [delayLog, setDelayLog] = useState([]);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const data = await ProjServices.getSingleProjectsDetails(id);
        if (data) {
          setProject(data.project_details[0]);
          setEmployeesData(data.project_data);
          setMissingHours(data.missing_hours);
          setPercentageCompletion(data.percentage_completion);
          setDelayLog(data.delay_log);
          console.log(data);
        }
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };
    fetchProjectDetails();
  }, [id]);

  if (!project) {return <div>No project details available.</div>;}

  const formatDate = (dateString) => {
    if (!dateString) return 'null';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');  
    const year = date.getFullYear().toString().slice(-4);  
    return `${day} - ${month} - ${year}`;
  };

  return (
    <div className="container">

     {/* Project Details Section */}
      {project && (
        <div className="contentCard">
          <h3>Project Details</h3>

          {/* Table 1: General Project Information */}
          <table className="projectDataTable">
            <thead>
              <tr>
                <th>Project Name</th>
                <th>Project Status</th>
                <th>Status Comment</th>
                <th>Contract Number</th>
                <th>Contracted Delivery Date</th>
                <th>Estimated Delivery Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{project.name}</td>
                <td>{project.status}</td>
                <td>{project.status_comment}</td>
                <td>{project.contract_reference_number}</td>
                <td>{formatDate(project.contracted_delivery_date)}</td>
                <td>{formatDate(project.estimated_delivery_date)}</td>
              </tr>
            </tbody>
          </table>

          {/* Table 2: Project Progress */}
          <table className="projectDataTable">
            <thead>
              <tr>
                <th>Consumed Budget</th>
                <th>Time Passed</th>
                <th>Time Passed With Delays</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>         
                  <div className="progress-bar">
                    <div className="progress" style={{ width: `${project.consumed_budget}%` }}> {project.consumed_budget} %</div>
                  </div>
                </td>
                <td>              
                  <div className="progress-bar">
                    <div className="progress" style={{ width: `${project.time_passed}%` }}> {project.time_passed} %</div>
                  </div>
                </td>
                <td>            
                  <div className="progress-bar">
                    <div className="progress" style={{ width: `${project.time_passed_with_delays}%` }}> {project.time_passed_with_delays} %</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

     {/* Percentage Completion Section */}
      {
        percentageCompletion.length > 0 && percentageCompletion.some(completion =>
          completion.percentage_complete !== null || 
          completion.date_added !== null || 
          completion.username !== null
        ) ? (
          <div className="contentCard">
            <h3>Percentage Completion</h3>
            <table className="projectDataTable">
              <thead>
                <tr>
                  <th>Employee</th>
                  <th>Percentage Complete</th>
                  <th>Date Added</th>
                 
                </tr>
              </thead>
              <tbody>
                {percentageCompletion.map((completion, index) => (
                  (completion.percentage_complete !== null || 
                  completion.date_added !== null || 
                  completion.username !== null) && (
                    <tr key={index}>
                      <td>{completion.username ?? 'null'}</td>
                      <td>
                        
                        <div className="progress-bar">
                          <div 
                            className="progress" 
                            style={{ width: `${completion.percentage_complete}%` }}>
                              {completion.percentage_complete !== null 
                          ? `${completion.percentage_complete} %` 
                          : 'Please Update Project Percentage'}
                          </div>
                        </div>
                      </td>
                      <td>{formatDate(completion.date_added) ?? 'null'}</td>             
                    </tr>
                  )
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="contentCard">
            <p><strong className="red">No percentage completion data for this project</strong></p>
          </div>
        )
      }

      {/* Delay Log Section */}
      <div className="contentCard">
        {
          delayLog.length > 0 && delayLog.some(delay =>
            delay.number_of_working_days !== null || 
            delay.start_date !== null || 
            delay.end_date !== null || 
            delay.description !== null || 
            delay.delay_type !== null || 
            delay.date_logged !== null ||
            delay.username !== null
          ) ? (
            <>
              <h3>Delay Log</h3>
              <table className="projectDataTable">
                <thead>
                  <tr>
                    <th>Employee</th>
                    <th>Working Days</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Description</th>
                    <th>Delay Type</th>
                    <th>Date Logged</th>
                  </tr>
                </thead>
                <tbody>
                  {delayLog.map((delay, index) => (
                    <tr key={index}>
                       <td>{delay.username !== null ? delay.username : 'null'}</td>
                      <td>{delay.number_of_working_days !== null ? delay.number_of_working_days : 'null'}</td>
                      <td>{delay.start_date !== null ? formatDate(delay.start_date) : 'null'}</td>
                      <td>{delay.end_date !== null ? formatDate(delay.end_date) : 'null'}</td>
                      <td>{delay.description !== null ? delay.description : 'null'}</td>
                      <td>{delay.delay_type !== null ? delay.delay_type : 'null'}</td>
                      <td>{delay.date_logged !== null ? formatDate(delay.date_logged) : 'null'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          ) : (
            <p><strong className="red">No delay for this project</strong></p>
          )
        }
      </div>

      {/* Project Data Section */}
      {employeesData.length > 0 && (
        <div className="contentCard">
          <h3>Project Data</h3>
          <table className="projectDataTable">
            <thead>
              <tr>
                <th>Employee</th>
                <th>Department</th>
                <th>Total Hours Worked</th>
              </tr>
            </thead>
            <tbody>
              {employeesData.map((employee, index) => {
                const displayName = employee.employee_info.name.split('@')[0];
                return (
                  <tr key={index}>
                    <td>{displayName}</td>
                    <td>{employee.employee_info.department}</td>
                    <td>{employee.employee_info.total_hours}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {/* Missing Hours Section */}
      {missingHours.length > 0 && (
        <div className="contentCard">
          <h3>Employees Missing Hours</h3>
          {missingHours
            .filter((entry) => entry.is_missing_hours)
            .map((entry, index) => {
              const employeeName = entry.username.split('@')[0];
              return (
                <div key={index}>
                  <li><strong className="red">{employeeName}</strong></li>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
export default SingleProjectsDetails;
