import React, { useState } from "react";
import styles from "./style.module.css";
import { AuthServices } from "../../../apis/service/AuthServices";
import toast from "react-hot-toast";

export default function Holidays() {
  const [disable, setDisable] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = () => {
    setDisable(true);
    const data = {
      start_date: fromDate,
      end_date: toDate,
      description: "",
    };
    AuthServices.holidays(data)
      .then(() => {
        toast.success("Projects submitted successfully!");
        setDisable(false);
        setSubmitted(true);
      })
      .catch((err) => {
        console.log(err, "err");
        toast.error("Projects submitted fail!");
        setDisable(false);
      });
  };

  return (
    <>

      <div className={styles.selection}>
        <h2>select a date :</h2>
        <div style={{ display: "flex", gap: "1vw" }}>
          <label>from:</label>
          <input
            type="date"
            id="fromDate"
            name="fromDate"
            onChange={(e) => setFromDate(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", gap: "1vw" }}>
          <label>to:</label>
          <input
            type="date"
            id="toDate"
            name="toDate"
            onChange={(e) => setToDate(e.target.value)}
          />
        </div>

      </div>

      <div className={styles.submitButton}>
        <button disabled={disable} onClick={() => handleSubmit()}>
          submit
        </button>
      </div>
      {submitted ? (
        <h3 style={{ textAlign: "center" }}>you make {fromDate} {toDate === fromDate ? null : `to ${toDate}`}{" "} holiday</h3>
      ) : null}
    </>

  );
}
