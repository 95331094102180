import { useEffect, useState } from "react";
import { FreelancerServices } from "../../../apis/service/FreelancerServices";
import { ProjServices } from "../../../apis/service/ProjServices";
import toast from "react-hot-toast";
import styles from "./freelancerStyle.css";

const AddFreelancer = () => {
  const [availableProjects, setAvailableProjects] = useState([]);

  const getAllProjects = () => {
    ProjServices.UserProjects()
      .then((data) => {
        console.log(data);
        setAvailableProjects(data);
      })
      .catch((error) => {
        console.error("Error fetching projects:", error);
      });
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  const initialFormData = {
    freelancer_name: "",
    specialty: "",
    hours: "",
    fees: "",
    date_contracted: "",
    date_delivered: "",
    date_payment_due: "",
    date_paid: "",
    project: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await FreelancerServices.addFreelancerInfo(formData);
      console.log("Success:", response);
      toast.success("Freelancer data submitted successfully!");
      setFormData(initialFormData); // Clear the form after successful submission
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to submit freelancer data.");
    }
  };

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Freelancer Name:</label>
          <input
            type="text"
            name="freelancer_name"
            value={formData.freelancer_name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Specialty:</label>
          <select
            name="specialty"
            value={formData.specialty}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select Specialty
            </option>
            <option value="Front-End">Front-End</option>
            <option value="Back-End">Back-End</option>
            <option value="Project Manager">Project Manager</option>
            <option value="Designer">Designer</option>
            <option value="Content Writer">Content Writer</option>
          </select>
        </div>
        <div>
          <label>Hours:</label>
          <input
            type="text"
            name="hours"
            value={formData.hours}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Fees:</label>
          <input
            type="text"
            name="fees"
            value={formData.fees}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Date Contracted:</label>
          <input
            type="date"
            name="date_contracted"
            value={formData.date_contracted}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Date Delivered:</label>
          <input
            type="date"
            name="date_delivered"
            value={formData.date_delivered}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Date Payment Due:</label>
          <input
            type="date"
            name="date_payment_due"
            value={formData.date_payment_due}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Date Paid:</label>
          <input
            type="date"
            name="date_paid"
            value={formData.date_paid}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Project:</label>
          <select
            name="project"
            value={formData.project}
            onChange={handleChange}
            required
          >
            <option value="" disabled>
              Select a Project
            </option>
            {availableProjects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.name} {/* (ID: {project.id}) */}
              </option>
            ))}
          </select>
        </div>
        <div className="btn">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default AddFreelancer;
